.participants-input {
    &.participants-input--open {
        @apply h-auto pb-6;
    }

    &__row {
        @apply flex w-full justify-between;
        flex: 1 1 78px;
        height: 78px;
    }
}

.participant-status__container {
    @apply flex justify-end;
    width: 90px;
}

.participant-status {
    background: rgba(239, 75, 90, 0.14);
    mix-blend-mode: normal;
    border-radius: 8px 8px 0px 8px;
    @apply flex items-center text-primary;
    padding: 0 7px 0 10px;
    font-size: 8px;
    height: 16px;
}

.pariticpant-thumbs {
    @apply flex pl-5 items-center;

    .thumb {
        margin-left: -10px;

        >div {
            width: 34px;
            height: 34px;

            >div {
                display: none;
            }
        }

        img {
            height: 34px;
            width: 34px;
            border-radius: 17px 17px 17px 0px;
            position: relative;
            margin-left: -2px;
        }
    }
}

.add-participant-button {
    @apply bg-[#3A2B46] h-8 w-8 rounded-full flex items-center text-center justify-center text-white pb-0.5 font-bold text-lg rounded-bl-none;
}

.participant-list {
    .participant-list__multi-value__label,
    .participant-list__indicators,
    .participant-list__multi-value {
        display: none;
    }

    .participant-list__control {
        font-size: 18px;
        border: none;
        background: rgba(255, 225, 194, 0.25);
        mix-blend-mode: normal;
        border-radius: 17px 17px 17px 0px;
    }
}

.form-input {
    .participant-list__menu {
        border: 1px solid rgba(255, 225, 194, 0.338089);
        box-shadow: 0px -21px 43px rgba(138, 104, 68, 0.12);
        border-radius: 0 0 17px 17px;
        margin: 0;
        padding: 16px 0;
    }

    .participant-list__menu-notice {
        font-size: 20px;
        padding: 0;
    }
}

.selected-participants {
    @apply w-full space-y-2.5;

    &__remove {
        background: rgba(239, 75, 90, 0.14);
        border-radius: 92px 92px 92px 0px;
        height: 34px;
        width: 34px;
        @apply flex items-center justify-center text-primary;
    }
}

.google-img {
    width: 34px;
    height: 34px;
    border-radius: 17px 17px 17px 0px;
}

.participant-list__option {
    >div {
        @apply flex items-center;
        margin-bottom: 5px;
    }

    img {
        margin-right: 10px;
        width: 34px;
        height: 34px;
        border-radius: 17px 17px 17px 0px;
    }

    &__title {
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        display: flex;
    }

    &__subtitle {
        font-size: 12px;
        line-height: 14px;
    }
}

.selected-participants__close {
    @apply flex items-center justify-center text-xs font-bold;
    background: rgba(58, 43, 70, 0.07);
    border-radius: 92px 92px 92px 0px;
    width: 34px;
    height: 34px;
}
