/* .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
} */

.footer-back {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-top-right-radius: 40vh;
    background-color: rgba(255, 225, 194, 0.34);
}

.footer-back.smaller {
    /* position: absolute; */
    /* left: 0px; */
    /* top: 0px; */
    /* z-index: -1; */
    /* width: 70%; */
    height: 100%;
}

.footer-back.smaller.final {
    width: 52%;
}

.footer-container {
    position: relative;
    color: rgba(58, 43, 70, 0.47);
}

.container.cols-two {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0px 20px 0px 20px;
}

.footer-logo {
    padding-top: 1.4vmin;
    padding-bottom: 1.4vmin;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
    -webkit-transform-origin: 0% 50%;
    -ms-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
}

.footer-logo-type {
    max-width: 73px;
    margin-left: 0.7vmin;
}

.social-icons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.footer-bottom {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.footer-top {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.social-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 40px;
    height: 40px;
    margin-left: 1.2vmin;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 40px;
    background-color: #ef4b5a;
    -webkit-transition: -webkit-transform 500ms cubic-bezier(.68, -.55, .265, 1.55);
    transition: -webkit-transform 500ms cubic-bezier(.68, -.55, .265, 1.55);
    transition: transform 500ms cubic-bezier(.68, -.55, .265, 1.55);
    transition: transform 500ms cubic-bezier(.68, -.55, .265, 1.55), -webkit-transform 500ms cubic-bezier(.68, -.55, .265, 1.55);
}

.w-inline-block {
    max-width: 100%;
    display: inline-block;
}

.container.cols-two.align-bottom {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: center;
}

.footer-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 2.7vmin;
    padding-bottom: 2.5vmin;
}

.tiny-txt {
    font-size: 10px;
    line-height: 160%;
    letter-spacing: 1px;
}

.tiny-txt.link {
    color: rgba(58, 43, 70, 0.47);
    text-decoration: none;
}

.tiny-txt.link:hover {
    color: #ef4b5a;
    text-decoration: underline;
}
.footer img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}

@media (max-width: 600px) {
    .footer-bottom, .footer-top{
        display: block;
    }

    .footer-menu, .social-icons {
        justify-content: center;
    }

    .tiny-txt, .footer-logo {
        margin-top: 10px;
        text-align: center;
    }
}