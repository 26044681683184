.timezone-input {
    &.timezone-input--open {
        @apply h-auto pb-6;
    }

    &__row {
        @apply flex w-full justify-between;
        flex: 1 1 78px;
        height: 78px;
    }
}
.timezone-list {
    .timezone-list__multi-value__label,
    .timezone-list__indicators,
    .timezone-list__multi-value {
        display: none;
    }

    .timezone-list__control {
        font-size: 18px;
        border: none;
        background: rgba(255, 225, 194, 0.25);
        mix-blend-mode: normal;
        border-radius: 17px 17px 17px 0px;
    }

    font-size: 15px;
}

.form-input {
    .timezone-list__menu {
        border: 1px solid rgba(255, 225, 194, 0.338089);
        box-shadow: 0px -21px 43px rgba(138, 104, 68, 0.12);
        border-radius: 0 0 17px 17px;
        margin: 0;
        padding: 16px 0;
    }

    .timezone-list__menu-notice {
        font-size: 20px;
        padding: 0;
    }
}
