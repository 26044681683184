.meeting-card {
    @apply bg-white p-6 rounded-primary w-72 flex-none;
    border: 1px solid rgba(255, 225, 194, 0.338089);
    box-shadow: 18px 21px 43px rgba(255, 225, 194, 0.213382);
    margin-left: 20px;

    .participants-row {
        @apply flex justify-between mix-blend-normal mb-2 rounded-full;
        background-color: rgba(#3A2B46, 0.04);
    }
}

.grid-meeting {
    @apply table-auto w-full bg-white mb-11;

    tbody {
        tr {
            height: 60px;
            box-shadow: 18px 21px -19px rgba(255, 225, 194, 0.213382);
            border: 1px solid rgba(255, 225, 194, 0.338089);
        }
    }

    &.grid-meeting--today {
        .grid-meeting__header {
            background: #52DEB9;
        }

        tbody {
            tr {
                height: 100px;
            }
        }

        .grid-meeting__time {
            background: rgba(82, 222, 185, 0.15709);
            color: black;
        }
    }
}
.grid-meeting__header {
    @apply w-36 text-white flex justify-between px-5 py-3 rounded-tl-[20px]  rounded-tr-[10px] font-normal;
    background-color: #3A2B46;
}

.grid-meeting__time {
    @apply w-36 rounded-br-[20px] text-center text-base;
    color: #EF4B5A;
}

.line-clamp-2 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: unset;
}

.MuiPopover-paper {
    width: 300px;
}

.custom-icon {
    color: #EF4B5A !important;
}