.change-btn {
  @apply bg-[#3A2B46] justify-center relative tracking-[3px] h-7 w-28 rounded-[15px] rounded-tr-none text-white text-sm font-bold flex items-center;
  border-radius: 0px 15px 15px 15px;
}

.btn {
  @apply px-6 uppercase rounded-primary text-base font-bold text-white tracking-[3.36px] py-4 rounded-tl-none transition-colors;

  @media (min-width: 1024px) {
    @apply h-16 text-base;
  }

  &.btn-padded {
    @media (min-width: 768px) {
        @apply px-20;
    }
  }
  
  &.btn-sm {
    @apply h-auto py-2 px-4 text-sm;
  }

  &.btn-lg {
    height: auto;
    font-size: 20px;
    padding: 24px 80px;

    @media (min-width: 1024px) {
        font-size: 25px;
        padding: 36px 92px;
    }
  }

  i {
    &:first-child {
        @apply mr-4;
    }

    &:last-child {
        @apply ml-4;
    }
  }
  
}
.btn-primary {
  @apply bg-primary;
  &:hover {
    @apply bg-primary-hover;
  }
}

.btn-secondary {
  @apply bg-transparent border border-[#EF4B5A] text-primary;

  &:hover {
    @apply bg-primary bg-opacity-10;
  }
}

.create-new-btn {
  @apply relative flex items-center justify-center opacity-0 transition-opacity;
  flex: 0 0 64px;

  &:hover {
    @apply opacity-100;
  }

  svg {
    @apply relative z-10;
  }

  &::after {
    content: '';
    @apply absolute top-0 w-px h-full bg-tertiary;
    left: 50%;
    margin-left: -1px;
  }
}
