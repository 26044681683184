@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'pages/login';
@import 'pages/dashboard';
@import 'components/buttons';
@import 'components/wheelpicker';
@import '../components/invoices/index.scss';
@import '../assets/fonts/Roboto/Roboto-Medium.ttf';


@font-face {
  font-family: "Roboto Medium";
  src: local("Roboto Medium"),
   url("../assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: bold;
}

html {
  height: 100%;
}

#root {
  // width: 100vw;
  overflow: hidden;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}

.form-input {
  @apply bg-white h-16 lg:h-20 placeholder-black w-full text-base lg:text-2xl relative flex justify-between items-center space-x-6 outline-none px-4 lg:px-6 placeholder-gray-400;
  border-radius: 20px;
  border: 1px solid rgba(255, 225, 194, 0.338089);
  box-shadow: 18px 21px 43px rgba(255, 225, 194, 0.213382);

  &:hover {
      border: 1px solid rgba(255, 225, 194, 0.3);
      box-shadow: 18px 21px 43px rgba(255, 225, 194, 0.213382);
  }

  &:focus {
      border: 1px solid #FFE1C2;
      box-shadow: 18px 21px 43px rgba(58, 43, 70, 0.12);
  }
}

textarea {
  &.form-input {
      @apply py-6;
      height: auto;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
      animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
      transform: rotate(0deg);
  }

  to {
      transform: rotate(360deg);
  }
}

.container-fluid {
  @apply max-w-7xl m-auto px-6;
}

.app-background {
  background: url('../assets/bottomleft.svg') left center no-repeat, url('../assets/spoonAndFork.png') center top no-repeat;
  background-size: 354px;
  background-color: #ffffc212;
  // background: url(img_flwr.gif) right bottom no-repeat, url(paper.gif) left top repeat;
}

.react-calendar {
  &.dashboard-calendar {
      @apply absolute z-10 shadow-lg top-10 left-0 max-w-none;
      width: 348px;
      margin-left: calc(-348px/2.5);
  }
}

.font-face-rb {
  font-family: "Roboto Medium";
 }


@media (max-width: 1280px) {
  .react-calendar {
    &.dashboard-calendar {
        @apply absolute z-10 shadow-lg top-10 left-0 max-w-none;
        width: 348px;
        margin-left: calc(-460px/2.5);
    }
  }  
}

@media (max-width: 640px) {
  .w-sm-60 {
    padding: 1.2vmin 2.1vmin 1.2vmin 2.5vmin !important;
    width: 9rem !important;
    font-size: 12px !important;
  }  
}

@media (min-width: 1024px){
  .genericModalButton {
    padding: 0 !important;
  }
}

#videoConference { height: 100vh; }

.wrapper {
  position: relative;
  min-height: 100vh;
}
