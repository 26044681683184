.overlay {
    z-index: 1;
    background: rgba(255, 253, 251, 0.81);
    backdrop-filter: blur(19.028px);
}
.price-popup {
    z-index: 1;
}

.meeting-grid-item {
    @apply transition-transform translate-x-0 block relative;
}

.meetingsContainer {
    &.menuActive {
        > div {
            > div {
                opacity: 0.5;
            }
        }

        .meeting-grid-item--active {
            opacity: 1;
            z-index: 1;
            transform: translateX(300px);
            position: fixed;
            right: 50%;

            @media (max-width: 768px) {
                right: -60% !important;
                left: auto;
            }
        }
    }
}

.grid-view {
    @apply flex-1;

    > div {
        @apply flex;;
        margin: 0 0 0 32px;

        @media (min-width: 1280px) {
            margin: 0;

            &:before {
                content: '';
                flex: 0 0 calc((100vw - 1280px)/2 + 24px);
                display: block;
            }
        }
    }
}

