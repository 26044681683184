input .form-input {
  margin-bottom: 2vmin;
  padding: 4.3vmin 0px 4.3vmin 2.5vmin;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(255, 225, 194, 0.34);
  border-radius: 2.5vmin;
  box-shadow: inset 0 0 0 0 #ffe1c2, 11px 14px 43px 0 rgba(255, 225, 194, 0.25);
  -webkit-transition: box-shadow 250ms cubic-bezier(.77, 0, .175, 1), border-color 250ms cubic-bezier(.77, 0, .175, 1);
  transition: box-shadow 250ms cubic-bezier(.77, 0, .175, 1), border-color 250ms cubic-bezier(.77, 0, .175, 1);
  letter-spacing: 0.07ch;
}

input .form-input:focus {
  border-color: #ffe1c2;
  box-shadow: inset 0 0 0 2px #ffe1c2, 11px 14px 43px 0 rgba(255, 225, 194, 0.7);
}

input::-webkit-input-placeholder .form-input {
  color: rgba(58, 43, 70, 0.52);
}

input:-ms-input-placeholder .form-input {
  color: rgba(58, 43, 70, 0.52);
}

input::-ms-input-placeholder .form-input {
  color: rgba(58, 43, 70, 0.52);
}

input::placeholder .form-input {
  color: rgba(58, 43, 70, 0.52);
}

.button {
  /* border-radius: 0px 2vmin 2vmin; */
  background-color: #ef4b5a;
  -webkit-transition: box-shadow 250ms cubic-bezier(.77, 0, .175, 1), -webkit-transform 250ms cubic-bezier(.77, 0, .175, 1);
  transition: box-shadow 250ms cubic-bezier(.77, 0, .175, 1), -webkit-transform 250ms cubic-bezier(.77, 0, .175, 1);
  transition: transform 250ms cubic-bezier(.77, 0, .175, 1), box-shadow 250ms cubic-bezier(.77, 0, .175, 1);
  transition: transform 250ms cubic-bezier(.77, 0, .175, 1), box-shadow 250ms cubic-bezier(.77, 0, .175, 1), -webkit-transform 250ms cubic-bezier(.77, 0, .175, 1);
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.52ch;
  text-transform: uppercase;
}

.btn-secondary {
  font-size: 15px !important;
}

.button:hover {
  box-shadow: 5px 5px 0 0 #3a2b46;
  -webkit-transform: translate(-7px, -7px);
  -ms-transform: translate(-7px, -7px);
  transform: translate(-7px, -7px);
}

.main {
  max-width: 1920px !important;
  padding: 0 !important;
}

.icons_container {
  width: auto;
  height: auto;
  padding: 8px 14px;
  border-radius: 10px;
  background-color: rgba(255, 225, 194, 0.34);
}

.meetings_footer {
  height: 140px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.container {
  max-width: 1500px;
  padding: 20px;
  margin: auto;
}

.vertical_border {
  width: 1px;
  background-color: rgba(58, 43, 70, 0.27);
  height: 30px;
}

.grid_share {
  max-height: calc(100vh - 150px);
  padding: 10px 8px 5px 10px;
}

.grid_share::-webkit-scrollbar {
  width: 5px;
}

.grid_share::-webkit-scrollbar-thumb {
  background-color: #3a2b46 !important;
}

.icon_container {
  min-width: 50px;
  height: 50px;
  border-radius: 10px;
}

/* for right sidebar like chat and userlist */
.v-card {
  padding: 20px 26px;
  height: calc(100vh - 150px);
  border-radius: 20px;
  background-color: #ffffff;
  flex: 1;
  color: #333333;
  width: calc(60vh - 150px) !important;
}

.v-card-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.invite_footer {
  padding-top: 20px;
}

/* utility classes */

.justify-center {
  justify-content: center;
}

.justify-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.m-auto {
  margin: auto;
}

.mt-auto {
  margin-top: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr10 {
  margin-right: 10px;
}

.ml10 {
  margin-left: 10px;
}

.ml30 {
  margin-left: 30px;
}

.point {
  cursor: pointer;
}

.disabled {
  color: gray !important;
  cursor: default !important;
}

.none {
  display: none;
}

.gap1 {
  gap: 10px !important;
}

.px5 {
  padding: 5px 0 !important;
}

.pr10 {
  padding-right: 10px;
}

.pt20 {
  padding-top: 20px;
}

.w-max {
  max-width: 100% !important;
}

.flex4 {
  flex: 4 !important;
}

.flex1 {
  flex: 1 !important;
}

.grid1 {
  grid-template-columns: 1fr !important;
}

.flex-column {
  flex-direction: column;
}

.v-scroll {
  overflow-y: scroll;
}

.v-scroll::-webkit-scrollbar {
  width: 5px;
}

.v-scroll::-webkit-scrollbar-thumb {
  background-color: #3a2b46;
}

.svg-white svg path {
  fill: white;
}

.border-none {
  border: none;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.bottom5 {
  bottom: 5px;
}

.right {
  right: 0;
}

.mirrored {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
}
.f-14 {
  font-size: 14px;
}

.color-white {
  color: #ffffff
}

.filter-block{
  font-size: 15px;
  background: #de3b4a;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 600px) {
  .v-card {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
  .meetings_footer {
    justify-content: center;
  }
  .container {
    padding: 10px;
  }
  .grid_share {
    max-height: 100%;
    margin-bottom: 140px;
  }
}

@media (max-width: 1400px) {
  .v-card {
    padding: 20px;
  }
}