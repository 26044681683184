.invoice-container {
    font-size: 16px;

    .dropdown {
        @apply flex border border-primary px-6 py-7 rounded-2xl rounded-tr-none bg-white -left-56 top-8 flex-col space-y-7;
        box-shadow: 10px 20px 43px rgba(138, 104, 68, 0.12);
    }
}
.table-head-bg {
    background: #FFE1C2;
}
.invoices-table {
    font-size: 10px;
    width: 99%;

    text-align: left;
    th{
        background: #FFE1C2;
        padding: 12px 48px 12px 0;
        font-size: 10px;
        letter-spacing: 3.4px;

        &:first-child {
            border-radius: 20px 0px 0px 0px;
        }

        &:last-child {
            border-radius: 0px 20px 0px 0px;
        }
    }

    td {
        padding: 0 48px 0 0;
        height: 86px;
        justify-content: center;
    }

    tbody {
        tr {
            @apply border border-tertiary;
        }
    }


}
.popup {
    .popup-container {
        width: 1000px;
        @apply m-auto p-10 bg-white;
        max-width: 90%;
        box-shadow: 18px 21px 43px rgba(58, 43, 70, 0.12);
        border-radius: 20px;
        border: 1px solid #FFE1C2;
        z-index: 9999;

        h2 {
            font-size: 27px;
        }



        .popup-container__actions {
            @apply grid grid-cols-1 md:grid-cols-2 gap-4 mt-14 justify-end md:gap-6;

            .btn {
                @apply w-full md:w-auto;
            }
        }
        .inner-dropdown {
            width: 159px;
            padding: 22px;
            left: auto;
            right: 60px;
            bottom: auto;
            top: auto;
            margin-top: 6px;
            @apply flex border border-primary px-6 py-7 rounded-2xl rounded-tr-none bg-white flex-col space-y-7;
            box-shadow: 10px 20px 43px rgba(138, 104, 68, 0.12);
        }
    }
    .btn-primary {
        box-shadow: 0px 4px 10px rgba(239, 75, 90, 0.25);
    }

}
.d-none{
    display: none;
}
.page-break-before{
    page-break-before: always;
}
