.react-calendar {
    width: 350px;
    max-width: 100%;
    // background: #3A2B46;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    color: #3A2B46;
    border-radius: 20px;
    overflow: hidden;
    font-size: 14px;
    background-color: white;
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    height: 52px;
    display: flex;
    border-radius: 20px 20px 0px 0px;
    color: white;
    background: #3A2B46;
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
    font-weight: bold;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: transparent;
}

.react-calendar__navigation button[disabled] {
    background-color: #3A2B46;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    @apply text-body text-opacity-50;
}

.react-calendar__month-view__weekNumbers {
    font-weight: bold;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--weekend {
    color: #3A2B46;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    /* padding: 2em 0.5em; */
}

.react-calendar__tile {
    @apply bg-transparent p-0 text-sm font-medium w-8 h-8 flex items-center justify-center rounded-full;

    &.react-calendar__tile--now {
        @apply text-primary;
    }

    &.react-calendar__tile--active {
        @apply bg-primary text-white;
    }

    &:focus {
        @apply bg-primary text-white;
    }
}

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    @apply rounded-full;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    @apply rounded-full bg-primary;
}

.react-calendar__tile--hasActive {
    background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}

.react-calendar__month-view  {
    @apply bg-white;
    border-radius: 20px 20px 0px 0px;
    padding: 14px 20px;
}
button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
    display: none;
}
.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
    font-size: 10px;
    font-weight: normal;

}
.wheelpicker:before {
    content: "";
    height: 140px;
    width: 100%;
    position: absolute;
    /* background: linear-gradient(to top, #F2F2F2 100%, #F2F2F2 0%); */
    background: linear-gradient(180deg, #FFFDFB 0%, rgba(255, 253, 251, 0.0001) 99.79%);
    z-index: 1;
    pointer-events: none;
    top: 0px;

}
.wheelpicker:after {
    content: "";
    height: 140px;
    width: 100%;
    position: absolute;
    /* background: linear-gradient(to top, #F2F2F2 100%, #F2F2F2 0%); */
    background: linear-gradient(0deg, #FFFDFB 0%, rgba(255, 253, 251, 0.0001) 99.79%);
    z-index: 1;
    pointer-events: none;
    bottom: 0px;

}

.wheelpicker ul::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.wheelpicker ul::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
.wheelpicker ul::-webkit-scrollbar-thumb {
  background: #EF4B5A; 
  border-radius: 15px;
}

/* Handle on hover */
.wheelpicker ul::-webkit-scrollbar-thumb:hover {
  background: #DE3B4A; 
}

.wheelpicker ul li span:first-child {
    color: #DE3B4A;
    font-size: 30px; 
}
.range-slider .css-187mznn-MuiSlider-root {
    color: #FFE1C2;
}
.range-slider .css-eg0mwd-MuiSlider-thumb:before{
    border: 1px solid #FFE1C2;
    box-shadow: none;
    padding: 8px;
}
.range-slider .css-1gv0vcd-MuiSlider-track {
    color: #FFE1C2;
    display: none;
}
.range-slider .css-14pt78w-MuiSlider-rail {
    background-color: #FFE1C2;
    height: 1px;
    opacity: 1;
    box-shadow: none;
    border: 1px solid #FFE1C2;
}
.range-slider .css-1tfve6q-MuiSlider-mark {
    @apply text-primary;
    padding: 3px;
    border: 1px solid #FFE1C2;
    border-radius: 6px;
}
.range-slider .css-6cwnna-MuiSlider-mark {
    @apply bg-primary;
    padding: 3px;
    border: 1px solid #FFE1C2;
    border-radius: 6px;
}
.range-slider .css-eg0mwd-MuiSlider-thumb::after {
    border: 1px solid #FFE1C2;
    width: 30px;
    height: 30px;
    left: 3px;
}
.range-slider .css-eg0mwd-MuiSlider-thumb {
    height: 6px;
    width: 6px;
    margin-left: 3px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
