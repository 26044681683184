.card-container {
    @apply rounded-primary pb-6 mr-6;
    width: 335px;
    border: 1px solid rgba(255, 225, 194, 0.3);
    background: white;
    margin-bottom: 15px;

    .card {
        height: 185px;
        .owner-info {
            font-size: 9px;
        }
    }
}