.customButton {
    width: 150px;
    height: 50px;
    letter-spacing: 0.5px;
    background-color: white;
    color: #3A2B46;
    font-size: 15px;
    border: 1.5px solid #E0314B;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 5px;
}

.active{
    background-color: #EF4B5A;
    border: 1.5px solid white;
    color: white;
}