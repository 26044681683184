@media (max-width: 850px) {
    .table-responsive {
      width: 100vh;
    }
}

.center-button {
  display: flex;
  align-items: center;
  justify-content: center;
}