
.form-card-input {
    @apply bg-white h-16 lg:h-20 placeholder-black w-full text-base lg:text-2xl relative flex justify-between items-center space-x-6 outline-none px-4 lg:px-6 placeholder-gray-400;
    border-radius: 20px;
    border: 1px solid rgba(255, 225, 194, 0.338089);
    box-shadow: 18px 21px 43px rgba(255, 225, 194, 0.213382);
    display: block !important;
    padding: 12px;
    height: 55px !important; 

    &:hover {
        border: 1px solid rgba(255, 225, 194, 0.3);
        box-shadow: 18px 21px 43px rgba(255, 225, 194, 0.213382);
    }

    &:focus {
        border: 1px solid #FFE1C2;
        box-shadow: 18px 21px 43px rgba(58, 43, 70, 0.12);
    }
}
  
@media (min-width: 1024px){
    .form-card-input {
        padding: 6px;
        height: 55px !important; 
    }
}
