.profile-container {
    .add-card {
        width: 335px;
        height: 185px;
        @apply bg-white rounded-primary border border-opacity-40 uppercase space-y-5 border-tertiary items-center justify-center flex flex-col;
        box-shadow: 18px 21px 43px rgba(255, 225, 194, 0.213382);
    }
}

.profile-tab {
    @apply p-7 text-left text-xl font-normal flex space-x-8 items-center text-body border border-tertiary rounded-primary bg-white font-semibold;

    &--active {
        @apply bg-tertiary;
    }
}

.profile-content input::placeholder {
    font-size: 16px !important;
}