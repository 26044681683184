  .pagination {
    display: flex;
    list-style: none;
    outline: none;
    justify-content: end;
    width:calc(100% - 320px);
    align-items: center;
  }
  .list-view .pagination {
    margin-left: 0;
  }
  .pagination > .active > a{
    border:1px solid red;
  }
  .pagination li {
    display: none;
  }
  .pagination li:first-child {
    display: block !important;
  }
  .pagination > li:last-child {
    display: block !important;
  }
  .pagination > li > a{
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .pagination .active {
    background-color:  transparent;
    border: 0;
    color: white;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    outline: none;
    margin: 20px;
  }
  .pagination > li > a, .pagination > li > span{
    color: #47ccde;
    border-radius: 15px;
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
  }

  @media only screen and (max-width: 1400px) and (min-width: 1300px)  {
    .pagination {
      width:calc(100% - 35px);
    }
  }

  @media only screen and (max-width: 1600px) and (min-width: 1400px)  {
    .pagination {
      width:calc(100% - 100px);
    }
  }


  @media (max-width: 1300px) {
    .pagination {
      width: 100%;
    }
  }

  @media (max-width: 600px) {
    .responsive-view-scroll {
      overflow-x: auto;
    }
    .dynamic-width {
      width: 100vh !important;
    }
  }